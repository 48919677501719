@import "~@ui/styles/tools";
@import "@app/styles/variables";

.container {
    --broker-size: 64px;
    --broker-padding: 24px;
    --broker-gap: 16px;
    --broker-title-size: 32px;

    background-color: #f6f7f8;
    border-radius: 12px;
    padding: var(--broker-padding);
    display: flex;
    gap: var(--broker-gap);
    flex-direction: column;

    @include media-tablet {
        --broker-size: 48px;
        --broker-padding: 16px;
        --broker-title-size: 24px;
    }

    &--size-small {
        --broker-size: 36px;
        --broker-padding: 16px;
        --broker-title-size: 22px;
    }
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--broker-gap);
}

.title {
    font-size: var(--broker-title-size);
    line-height: 1.2;
    font-weight: 600;
}

.icon {
    padding: var(--broker-padding);
    width: calc(var(--broker-size) + var(--broker-padding) * 2);
    height: calc(var(--broker-size) + var(--broker-padding) * 2);
    background: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: var(--broker-size);
        max-height: var(--broker-size);
    }
}

.button {
    width: calc(100% + 8px);

    &__container {
        align-self: center;
        width: 100%;
        max-width: 250px;
    }
}