@import "~@ui/styles/tools";

.rect {
    width: 57px;
    height: 60px;
    fill: $color-background;
    border-radius: 8px;
    transform: translate(-60px, -30px);

    @include media-tablet {
        width: 42px;
        height: 43px;
        transform: translate(-40px, -21px);
    }

    @include media-mobile {
        width: 32px;
        height: 35px;
        transform: translate(-30px, -17px);
    }
}

.value {
    transform: translateX(-32px);
    font-size: $font-size--xlarge;
    font-weight: 600;
    text-align: center;
    font-family: SF Pro Display, sans-serif;

    @include media-tablet {
        font-size: $font-size--large;
        transform: translateX(-20px);
    }

    @include media-mobile {
        transform: translateX(-15px);
    }
}

.text {
    transform: translate(-52px, 16px);
    font-size: $font-size--normal;
    font-weight: 600;
    text-align: center;
    fill: #00000080;
    font-family: SF Pro Display, sans-serif;

    @include media-tablet {
        font-size: 13px;
        transform: translate(-36px, 16px);
    }
    @include media-mobile {
        font-size: 10px;
        transform: translate(-27px, 13px);
    }
}

.xAxis {
    fill: black;
    width: 1px;
    height: 20px;
    transform: translateY(-11px);
    @include media-tablet {
        height: 15px;
    }
}

.x-axis {
    font-weight: 500;
    font-size: 16px;
    font-family: Inter, sans-serif;

    @include media-laptop {
        font-size: 13px;
    }

    @include media-tablet {
        display: none;
    }
}
